<template>
  <div
    class="button-container"
    :class="{ 'button-empty': !showRowLeft && !showRowRight }"
  >
    <a-row class="mobile-row-social">
      <div
        v-if="showBtnAngleLeft"
        class="btn-angle-left"
        @click="slideLeft"
      >
        <FeatherIcon
          type="chevron-left"
          size="16"
        />
      </div>
      <div
        v-if="showRowLeft"
        class="row-left"
      >
        <a-col
          :xl="4"
          :lg="8"
          :xs="8"
        >
          <a-popover
            placement="bottomLeft"
            trigger="hover"
          >
            <template #content>
              <div class="pop-data-wrapper">
                <div class="title">Mentioned</div>
                <div class="value-wrapper">
                  <div class="value">{{ overviewData.mention.formatted }}</div>
                </div>
              </div>
            </template>
            <FacebookOverviewButton
              class="cursor-pointer"
              :selected="selectedValueType === 'mentioned'"
              :disable-tooltip="true"
              title="Mentioned"
              :loading="loading"
              :value="overviewData.mention.value"
              :percent-change="overviewData.mention.percentChange"
              @click="select('mentioned')"
            />
          </a-popover>
        </a-col>
        <a-col
          :xl="4"
          :lg="8"
          :xs="8"
        >
          <a-popover
            placement="bottomLeft"
            trigger="hover"
          >
            <template #content>
              <EngagementDetail
                :total="overviewData.engagement.value"
                :comments="overviewData.comment.value"
                :comments-percent-change="overviewData.comment.percentChange"
                :reaction="overviewData.reaction.value"
                :reaction-percent-change="overviewData.reaction.percentChange"
                :share="overviewData.share.value"
                :share-percent-change="overviewData.share.percentChange"
              />
            </template>
            <FacebookOverviewButton
              class="cursor-pointer"
              :selected="selectedValueType === 'engagement'"
              title="Engagement"
              :loading="loading"
              :disable-tooltip="true"
              :value="overviewData.engagement.value"
              :percent-change="overviewData.engagement.percentChange"
              @click="select('engagement')"
            />
          </a-popover>
        </a-col>
        <!-- <a-col :xl="3" :lg="8" :xs="8">
          <FacebookOverviewButton
            class="cursor-pointer"
            :selected="selectedValueType === 'view'"
            :disable-tooltip="true"
            title="View"
            :loading="loading"
            :value="overviewData.view.value"
            :percent-change="overviewData.view.percentChange"
          />
        </a-col> -->
        <a-col
          :xl="4"
          :lg="8"
          :xs="8"
          class="border-right"
        >
          <FacebookOverviewButton
            class="cursor-pointer"
            :selected="selectedValueType === 'videoView'"
            :disable-tooltip="true"
            title="Video View"
            :loading="loading"
            :value="overviewData.videoView.value"
            :percent-change="overviewData.videoView.percentChange"
          />
        </a-col>
      </div>
      <div
        v-if="showRowRight"
        class="row-right"
      >
        <a-col
          :xl="3"
          :lg="8"
          :xs="8"
        >
          <FacebookOverviewButton
            class="cursor-pointer"
            :selected="selectedValueType === 'comment'"
            :disable-tooltip="true"
            title="Like"
            icon="heart"
            :loading="loading"
            :value="overviewData.reaction.value"
            :percent-change="overviewData.reaction.percentChange"
          />
        </a-col>
        <a-col
          :xl="3"
          :lg="8"
          :xs="8"
        >
          <FacebookOverviewButton
            class="cursor-pointer"
            :selected="selectedValueType === 'comment'"
            :disable-tooltip="true"
            title="Reply"
            icon="message-square"
            :loading="loading"
            :value="overviewData.comment.value"
            :percent-change="overviewData.comment.percentChange"
          />
        </a-col>
        <a-col
          :xl="3"
          :lg="8"
          :xs="8"
        >
          <FacebookOverviewButton
            class="cursor-pointer"
            :selected="selectedValueType === 'retweet'"
            :disable-tooltip="true"
            title="Retweet"
            icon="repeat"
            :loading="loading"
            :value="overviewData.retweet.value"
            :percent-change="overviewData.retweet.percentChange"
          />
        </a-col>
        <a-col
          :xl="3"
          :lg="8"
          :xs="8"
        >
          <FacebookOverviewButton
            class="cursor-pointer"
            :selected="selectedValueType === 'quote'"
            :disable-tooltip="true"
            title="Quote"
            icon="edit-3"
            :loading="loading"
            :value="overviewData.quote.value"
            :percent-change="overviewData.quote.percentChange"
          />
        </a-col>
      </div>
    </a-row>
    <div
      v-if="showBtnAngleRight"
      class="btn-angle-right"
      @click="slideRight"
    >
      <FeatherIcon
        type="chevron-right"
        size="16"
      />
    </div>
  </div>
</template>
<script>
import FacebookOverviewButton from '@/components/Button/FacebookOverviewButton';
import { ref, onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import EngagementDetail from '@/components/Detail/EngagementDetail';

export const TwitterOverview = {
  name: 'TwitterOverview',
  components: {
    FacebookOverviewButton,
    EngagementDetail,
  },
  props: {
    overviewData: Object,
    loading: Boolean,
  },
  setup(props, { emit }) {
    const store = useStore();
    const isMobileScreen = computed(() => store.state.isMobileScreen);
    let selectedValueType = ref('mentioned');
    let showRowLeft = ref(true);
    let showRowRight = ref(false);
    let showBtnAngleLeft = ref(false);
    let showBtnAngleRight = ref(true);
    let windowWidth = ref(window.innerWidth);
    const select = (valueType) => {
      selectedValueType.value = valueType;
      emit('typeChange', valueType);
    };

    const onWidthChange = () => {
      windowWidth.value = window.innerWidth;
      if (showRowLeft.value === true) {
        if (windowWidth.value <= 600) {
          showRowRight.value = false;
          showBtnAngleLeft.value = false;
          showBtnAngleRight.value = true;
          showRowLeft.value = true;
        } else {
          showRowLeft.value = true;
          showRowRight.value = true;
        }
      } else {
        if (windowWidth.value <= 600) {
          showRowRight.value = true;
          showBtnAngleLeft.value = true;
          showBtnAngleRight.value = false;
          showRowLeft.value = false;
        } else {
          showRowLeft.value = true;
          showRowRight.value = true;
        }
      }
    };

    const slideLeft = () => {
      if (windowWidth.value >= 600) {
        showRowRight.value = true;
        showBtnAngleLeft.value = true;
        showBtnAngleRight.value = false;
      } else {
        showRowLeft.value = true;
        showRowRight.value = false;
        showBtnAngleLeft.value = false;
        showBtnAngleRight.value = true;
      }
    };

    const slideRight = () => {
      if (windowWidth.value >= 600) {
        showRowLeft.value = true;
        showBtnAngleLeft.value = false;
        showBtnAngleRight.value = true;
      } else {
        showRowLeft.value = false;
        showRowRight.value = true;
        showBtnAngleLeft.value = true;
        showBtnAngleRight.value = false;
      }
    };

    onMounted(() => {
      window.addEventListener('resize', onWidthChange);
      onWidthChange();
    });
    return {
      select,
      selectedValueType,
      slideLeft,
      slideRight,
      showRowLeft,
      showRowRight,
      showBtnAngleLeft,
      showBtnAngleRight,
      isMobileScreen,
    };
  },
};
export default TwitterOverview;
</script>
<style lang="scss" scoped>
.row-left,
.row-right {
  display: contents;
  // display: flex;
}
.btn-angle-right,
.btn-angle-left {
  display: none;
}
.button-container {
  /*display: flex;*/
  width: 100%;
}
.pop-data-wrapper {
  .title {
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #9299b8;
    align-items: center;
    display: flex;
  }

  .value {
    font-family: DM Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    color: #272b41;
    margin-bottom: 10px;
  }
}

.row-social {
  // justify-content: space-between;
  align-items: center;
}
.border-right {
  border-right: 1px solid #f1f2f6;
}
.mr-icon {
  margin-right: 5px;
}
// @media only screen and (max-width: 1200px) {
//   .row-left,
//   .row-right {
//     display: flex;
//   }
// }

@media only screen and (max-width: 960px) {
  .border-right {
    border-right: none;
  }
  // .row-left,
  // .row-right {
  //   display: contents;
  // }
}

@media only screen and (max-width: 600px) {
  .button-container {
    display: flex;
    align-items: center;
  }
  .btn-angle-left,
  .btn-angle-right {
    display: block;
    cursor: pointer;
    width: 24px;
    height: 24px;
    // left: 0px;
    // top: 111px;
    background: #e0eaff;
    box-shadow: 0px 0px 10px rgba(51, 113, 255, 0.2);
    border-radius: 24px;
    padding: 4px;
    z-index: 999;
  }

  .btn-angle-right {
    margin-right: 10px;
    margin-left: 5px;
  }

  .btn-angle-left {
    position: absolute;
    margin-left: -25px;
  }

  .mobile-row-social {
    width: 450px;
    align-items: center;
    margin-left: 20px;
    // flex-flow: unset;
  }
}
</style>
