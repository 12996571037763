<template>
  <div class="list-wrapper">
    <div
      v-for="(item, index) in onlyTop10Data"
      :key="item.info.id"
      class="list-item"
    >
      <MessageItem
        :item="item"
        :order-no="index + 1"
        :show-category="true"
        :has-thumb="true"
        :showLongText="true"
        :parentName="'toppost'"
        @click="drilldown(item)"
      />
    </div>
    <!-- <SeeMoreDialog /> -->
  </div>
</template>

<script>
import { ref, computed, provide, toRefs } from 'vue';
import { useStore } from 'vuex';
import helper from '@/services/helper';
import MessageItem from '@/views/Overview/MessageItem.vue';
// import SeeMoreDialog from '@/components/Seemore/SeeMoreDialog.vue';
export default {
  name: 'CampaignChannelTopEngage',
  components: {
    MessageItem,
    // SeeMoreDialog,
  },
  props: {
    filterResult: Object,
    topPost: Object,
  },
  setup(props) {
    const store = useStore();
    const { filterResult, topPost } = toRefs(props);
    const error = ref(false);
    const isMobileScreen = computed(() => store.state.isMobileScreen);
    const campaignLevel = computed(
      () => store.state.account.campaignMenuData.categoryLevel,
    );
    const expandRowId = ref('');
    const toggleExpandRow = (id) => {
      if (expandRowId.value === id) {
        expandRowId.value = '';
      } else {
        expandRowId.value = id;
      }
    };

    const onlyTop10Data = computed(() => {
      if (topPost.value.data) {
        return topPost.value.data.slice(0, 10);
      }
      return [];
    });
    const subCategoryName = computed(() => {
      if (topPost.value.subCategoryName) {
        return topPost.value.subCategoryName;
      }
      return [];
    });

    const showMessageModal = (userId, username) => {
      let f = {
        payload: {
          title: `Messages from "${username}"`,
        },
        criteria: {
          ...filterResult.value,
          userId,
          sort: {
            direction: 'desc',
            type: 'engagement_score',
          },
          highlight: {
            enable: true,
          },
        },
        type: 'influencer',
      };
      if (campaignLevel.value > 0) {
        f.criteria['subCategory'] = filterResult.value.subCategory;
      } else {
        f.criteria['category'] = filterResult.value.category;
        f.criteria['subCategory'] = [];
      }
      store.dispatch('message/showMessageModal', f);
    };

    const drilldown = (item) => {
      store.dispatch('seemore/show', item);
    };

    const clickMenu = (id) => {
      const scrollElement = document.getElementById(id);
      // const scrollElement = document.getElementsByClassName('user-influencer-menu');
      if (scrollElement) {
        scrollElement.style.display = 'block';
      }
    };

    const openUserLink = (item) => {
      if (item && item.info && item.info.user_link) {
        helper.openUrl(item.info.user_link);
      }
    };

    const copyUserLink = (item) => {
      if (item && item.info && item.info.user_link) {
        helper.copyUrl(item.info.user_link);
      }
    };

    const onImageError = (e, source) => {
      const img = helper.getDefaultPlaceholder(source);
      e.target.src = img;
    };

    provide('onEditMessage', (e) => {
      helper.saveLocalMessage(e, topPost.value.data);
    });
    provide('showMode', '');

    provide('subCategoryName', subCategoryName);

    return {
      error,
      onlyTop10Data,
      subCategoryName,
      drilldown,
      clickMenu,
      openUserLink,
      copyUserLink,
      onImageError,
      showMessageModal,
      isMobileScreen,
      toggleExpandRow,
      expandRowId,
    };
  },
};
</script>

<style lang="scss" scoped>
.list-wrapper {
  margin: 14px 0 0 0;
  padding: 0;
  .list-item {
    margin-bottom: 10px;
    // border: 1px solid #f0f2f5;
    box-shadow: 0 5px 20px rgb(172 172 172 / 23%);
    border-radius: 5px;
  }
}
</style>
