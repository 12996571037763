<template>
  <a-tooltip placement="bottom">
    <template
      v-if="!disableTooltip"
      #title
    >
      <span>{{ title }}: {{ fullValue }}</span>
    </template>
    <div class="stat-button">
      <div class="title-overview">
        <FeatherIcon
          v-if="icon"
          :type="icon"
          size="14"
          class="engage-icon mr-icon"
        />
        <div>{{ title }}</div>
      </div>
      <div class="value-wrapper">
        <a-skeleton
          :loading="loading"
          :paragraph="false"
        >
          <div class="value">
            {{ formattedValue }}
          </div>
          <div class="percent-wrapper">
            <div class="percent">
              <div
                v-if="percentChange !== 'Infinity'"
                :class="[
                  'percent-content',
                  percentChange >= 0
                    ? 'percent-content-positive'
                    : 'percent-content-negative',
                ]"
              >
                <font-awesome-icon
                  :icon="percentChange >= 0 ? faArrowUp : faArrowDown"
                />
                {{ formattedPercentChange }}
              </div>
              <div
                v-else
                class="percent-content percent-content-positive"
              ></div>
              <!-- <div v-else class="percent-content percent-content-positive">
                <font-awesome-icon :icon="faArrowUp" /> <font-awesome-icon :icon="['fas', 'infinity']" /> %
              </div> -->
            </div>
          </div>
        </a-skeleton>
      </div>
    </div>
  </a-tooltip>
</template>
<script>
import numeral from 'numeral';
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import VueTypes from 'vue-types';
import { toRefs, computed } from 'vue';

export const FacebookOverviewButton = {
  name: 'FacebookOverviewButton',
  props: {
    selected: VueTypes.bool.def(false),
    icon: { type: String, Default: '' },
    title: VueTypes.string.def(''),
    value: VueTypes.number.def(0),
    percentChange: VueTypes.number.def(0),
    disableTooltip: Boolean,
    loading: Boolean,
  },
  setup(props) {
    let { selected, icon, title, value, percentChange, loading } =
      toRefs(props);
    const formattedValue = computed(() => {
      return numeral(value.value).format('0[.]0a');
    });
    const formattedPercentChange = computed(() => {
      return numeral(percentChange.value / 100)
        .format('0a %')
        .replace('-', '');
    });
    const fullValue = computed(() => {
      return numeral(value.value).format('0,0');
    });
    return {
      faArrowUp,
      faArrowDown,
      selected,
      icon,
      title,
      value,
      percentChange,
      formattedValue,
      formattedPercentChange,
      fullValue,
      loading,
    };
  },
};
export default FacebookOverviewButton;
</script>
<style scoped>
.title-overview div {
  display: inline-block;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.stat-button {
  height: 110px;
  /* min-width: 190px; */
  width: 100%;
  padding: 20px 28px 20px 20px;
  /* margin: 20px 28px 20px 30px; */
  display: flex;
  flex-direction: column;
  /* justify-content: space-around; */
  transition: all 0.2s ease-in;
}

.title-overview {
  font-family: DM Sans, serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  /* transition: all 0.2s ease-in; */
  color: #9299b8;
  align-items: center;
  display: flex;
}

.stat-button:hover {
  background-color: #f8f9fb;
}

.selected {
  transition: all 0.2s ease-in;
  background-color: #337df6;
  /*pointer-events: none;*/
}

.selected:hover {
  background-color: #337df6;
  /*pointer-events: none;*/
}

.selected-text {
  transition: all 0.2s ease-in;
  color: #ffffff;
}

.value {
  font-family: DM Sans, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 31px;
  min-width: 75px;
  transition: all 0.2s ease-in;
}

.value-wrapper {
  /* display: flex; */
  flex-wrap: wrap;
  justify-content: space-between;
}

.percent-wrapper {
  display: flex;
  align-items: flex-end;
  min-width: 65px;
}

.selected-percent {
  transition: all 0.2s ease-in;
  background-color: white;
}

.percent {
  border-radius: 9px;
  height: min-content;
  text-align: center;
}

.percent-content-positive {
  color: #5bbe94;
}

.percent-content-negative {
  color: #ff4d4f;
}

.percent-content {
  height: 22px;
  transition: all 0.2s ease-in;
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin-left: 3px;
  margin-right: 3px;
}

.load-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.mr-icon {
  margin-right: 5px;
}

@media only screen and (max-width: 600px) {
  .title-overview div {
    display: inline-block;
    width: 70px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .stat-button {
    padding: 20px 10px 20px 10px;
  }
}

@media only screen and (max-width: 1200px) {
  .stat-button {
    min-width: unset;
  }
}
</style>
