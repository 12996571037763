<template>
  <a-card
    v-resize="hideOverflowTitle"
    :widget="true"
    class="word-cloud-page no-body-padding"
    title="Word Cloud"
  >
    <template
      v-if="!isMobileScreen"
      #extra
    >
      <a-radio-group
        v-model:value="wordCloudMode"
        button-style="solid"
        size="default"
      >
        <a-radio-button
          v-for="opt in modeOptions"
          :key="opt.value"
          :value="opt.value"
          style="height: 34px"
        >
          {{ opt.label }}
        </a-radio-button>
      </a-radio-group>
    </template>
    <template
      v-else
      #extra
    >
      <a-dropdown
        placement="bottomRight"
        :getPopupContainer="(trigger) => trigger.parentNode"
      >
        <FeatherIcon
          class="more-menu"
          type="more-horizontal"
          size="16"
        />
        <template #overlay>
          <a-menu id="mode-menu-mobile">
            <a-menu-item-group title="TYPE:">
              <a-menu-item
                class="item-btn"
                style="background: #fff"
              >
                <a-button
                  :type="wordCloudMode === 'hashtag' ? 'primary' : 'ghost'"
                  class="circular btn-line"
                  :class="{
                    'button-primary-light': wordCloudMode === 'hashtag',
                  }"
                  style="width: 42px"
                  @click="wordCloudMode = 'hashtag'"
                >
                  <FeatherIcon
                    type="hash"
                    size="18"
                  />
                </a-button>
                <a-button
                  :type="wordCloudMode === 'word' ? 'primary' : 'ghost'"
                  class="circular"
                  :class="{ 'button-primary-light': wordCloudMode === 'word' }"
                  style="width: 42px"
                  @click="wordCloudMode = 'word'"
                >
                  <span
                    ><img
                      class="icon-font-case"
                      src="@/assets/images/icon/font-case.svg" /></span
                ></a-button>
              </a-menu-item>
            </a-menu-item-group>
          </a-menu>
        </template>
      </a-dropdown>
    </template>

    <div
      v-if="loading"
      class="loading-wrapper"
    >
      <a-skeleton
        :loading="loading"
        active
      />
    </div>

    <!-- Content -->
    <a-row
      v-else
      class="word-cloud-content"
    >
      <a-col
        :xs="24"
        :sm="24"
        :md="12"
        class="word-cloud-card"
      >
        <canvas
          id="wc"
          ref="wc"
          class="wordCloud-canvas"
          width="480"
          height="300"
        ></canvas>
      </a-col>
      <a-col
        :xs="24"
        :sm="24"
        :md="12"
        class="word-list-block"
      >
        <div class="table-word-cloud table-bordered">
          <a-table
            rowKey="no"
            :dataSource="selectModeType"
            :columns="wordCloudMode == 'hashtag' ? columns : columnsWords"
            :pagination="false"
            :showHeader="true"
            class="table-trending"
            ellipsize="{true}"
            rowClassName="table-row"
            :customRow="rowClick"
          >
            <template #no="{ text }">
              <span class="no-text">{{ text }}</span>
            </template>
          </a-table>
        </div>
      </a-col>
    </a-row>
  </a-card>
</template>

<script>
import { ref, computed, toRefs, watch, reactive, onBeforeMount } from 'vue';
import { useStore } from 'vuex';
import WordCloud from 'wordcloud';
import numeral from 'numeral';
import helper from '@/services/helper';
import api from '@/services/api';
export default {
  name: 'Trending',
  props: {
    type: String,
    mode: String,
    filterResult: Object,
    categoryByLevel: Object,
  },
  setup(props) {
    let { mode, filterResult } = toRefs(props);
    const store = useStore();
    const isMobileScreen = computed(() => store.state.isMobileScreen);
    const brand = computed(() => store.state.account.brand);
    const wordCloudMode = ref('hashtag');
    let loading = ref(false);
    let wc = ref();
    let maxVal = 0;
    let totalCount = 0;
    const wordEngagementData = ref([]);
    const wordMentionData = ref([]);
    const wordViewData = ref([]);
    const wordDataByMode = reactive({
      engagement: [],
      mention: [],
      view: [],
    });
    const hashtagEngagementData = ref([]);
    const hashtagMentionData = ref([]);
    const hashtagViewData = ref([]);
    const hashtagDataByMode = reactive({
      engagement: [],
      mention: [],
      view: [],
    });
    const modeOptions = [
      { label: 'Hashtag', value: 'hashtag' },
      { label: 'Word', value: 'word' },
    ];

    const columnByMode = {
      engagement: [
        {
          title: '#',
          dataIndex: 'no',
          key: 'no',
          slots: { customRender: 'no' },
          width: 65,
        },
        {
          title: 'Hashtag',
          dataIndex: 'hashtag',
          key: 'hashtag',
          ellipsis: true,
          width: 100,
          onCell: () => {
            return {
              style: {
                whiteSpace: 'nowrap',
                maxWidth: 100,
              },
            };
          },
        },
        {
          title: 'Engagement',
          dataIndex: 'engagement',
          key: 'engagement',
          width: 110,
        },
      ],
      mention: [
        {
          title: '#',
          dataIndex: 'no',
          key: 'no',
          slots: { customRender: 'no' },
          width: 65,
        },
        {
          title: 'Hashtag',
          dataIndex: 'hashtag',
          key: 'hashtag',
          ellipsis: true,
          width: 100,
          onCell: () => {
            return {
              style: {
                whiteSpace: 'nowrap',
                maxWidth: 100,
              },
            };
          },
        },
        {
          title: 'Mentioned',
          dataIndex: 'engagement',
          key: 'engagement',
          width: 110,
        },
      ],
      view: [
        {
          title: '#',
          dataIndex: 'no',
          key: 'no',
          slots: { customRender: 'no' },
          width: 65,
        },
        {
          title: 'Hashtag',
          dataIndex: 'hashtag',
          key: 'hashtag',
          ellipsis: true,
          width: 100,
          onCell: () => {
            return {
              style: {
                whiteSpace: 'nowrap',
                maxWidth: 100,
              },
            };
          },
        },
        {
          title: 'View',
          dataIndex: 'engagement',
          key: 'engagement',
          width: 110,
        },
      ],
    };

    const columnsWordsByMode = {
      engagement: [
        {
          title: '#',
          dataIndex: 'no',
          key: 'no',
          slots: { customRender: 'no' },
          width: 65,
        },
        {
          title: 'Word',
          dataIndex: 'hashtag',
          key: 'hashtag',
          ellipsis: true,
          width: 100,
          onCell: () => {
            return {
              style: {
                whiteSpace: 'nowrap',
                maxWidth: 100,
              },
            };
          },
        },
        {
          title: 'Engagement',
          dataIndex: 'engagement',
          key: 'engagement',
          width: 110,
        },
      ],
      mention: [
        {
          title: '#',
          dataIndex: 'no',
          key: 'no',
          slots: { customRender: 'no' },
          width: 65,
        },
        {
          title: 'Word',
          dataIndex: 'hashtag',
          key: 'hashtag',
          ellipsis: true,
          width: 100,
          onCell: () => {
            return {
              style: {
                whiteSpace: 'nowrap',
                maxWidth: 100,
              },
            };
          },
        },
        {
          title: 'Mentioned',
          dataIndex: 'engagement',
          key: 'engagement',
          width: 110,
        },
      ],
      view: [
        {
          title: '#',
          dataIndex: 'no',
          key: 'no',
          slots: { customRender: 'no' },
          width: 65,
        },
        {
          title: 'Word',
          dataIndex: 'hashtag',
          key: 'hashtag',
          ellipsis: true,
          width: 100,
          onCell: () => {
            return {
              style: {
                whiteSpace: 'nowrap',
                maxWidth: 100,
              },
            };
          },
        },
        {
          title: 'View',
          dataIndex: 'engagement',
          key: 'engagement',
          width: 110,
        },
      ],
    };

    const columns = ref([]);

    const columnsWords = ref([]);

    const rowClick = (record) => {
      return {
        onClick: () => {
          drilldownFn('("' + record.hashtag + '")');
        },
      };
    };

    // mode by Engagement
    const initHashtagEngagementData = async () => {
      const limit = 20;
      let response;
      response = await api
        .getHashtagEngagement(filterResult.value, brand.value, limit)
        .catch(() => {});
      hashtagDataByMode.engagement = response.message;
      hashtagEngagementData.value = hashtagDataByMode[mode.value];
    };

    const initWordEngagementData = async () => {
      const limit = 20;
      let response = await api
        .getWordEngagement(filterResult.value, brand.value, limit)
        .catch(() => {});
      wordDataByMode.engagement = response.message;
      wordEngagementData.value = wordDataByMode[mode.value];
    };

    // mode by Mentioned
    const initHashtagMentionData = async () => {
      const limit = 20;
      let response;
      response = await api
        .getHashtagCount(filterResult.value, brand.value, limit)
        .catch(() => {});
      hashtagDataByMode.mention = response.message;
      hashtagMentionData.value = hashtagDataByMode[mode.value];
    };

    const initWordMentionData = async () => {
      const limit = 20;
      let response;
      response = await api
        .getWordCount(filterResult.value, brand.value, limit)
        .catch(() => {});
      wordDataByMode.mention = response.message;
      wordMentionData.value = wordDataByMode[mode.value];
    };

    // mode by view
    const initHashtagViewData = async () => {
      const limit = 20;
      let response;
      response = await api
        .getHashtagView(filterResult.value, brand.value, limit)
        .catch(() => {});
      hashtagDataByMode.view = response.message;
      hashtagViewData.value = hashtagDataByMode[mode.value];
    };

    const initWordViewData = async () => {
      const limit = 20;
      let response;
      response = await api
        .getWordView(filterResult.value, brand.value, limit)
        .catch(() => {});
      wordDataByMode.view = response.message;
      wordViewData.value = wordDataByMode[mode.value];
    };

    const convertWordEngagementList = computed(() => {
      let data;
      let sum = 0;
      const list = [];
      totalCount = 0;
      if (wordCloudMode.value === 'hashtag') {
        data = hashtagEngagementData.value;
        if (data && data.length > 0) {
          data.forEach((value) => {
            sum += parseInt(value.count);
          });
          data.forEach((value, index) => {
            list.push({
              no: index + 1,
              hashtag: value.word,
              engagement:
                helper.numeral(value.count) +
                ' (' +
                numeral((value.count / sum) * 100).format('0.00') +
                '%)',
            });
            totalCount += value.count;
          });
        }
      } else if (wordCloudMode.value === 'word') {
        data = wordEngagementData.value;
        if (data && data.length > 0) {
          data.forEach((value) => {
            sum += parseInt(value.count);
          });
          data.forEach((value, index) => {
            list.push({
              no: index + 1,
              hashtag: value.word,
              engagement:
                helper.numeral(value.count) +
                ' (' +
                numeral((value.count / sum) * 100).format('0.00') +
                '%)',
            });
            totalCount += value.count;
          });
        }
      }
      return list;
    });

    const convertWordMentionList = computed(() => {
      let data;
      const list = [];
      let sum = 0;
      totalCount = 0;
      if (wordCloudMode.value === 'hashtag') {
        data = hashtagMentionData.value;
        if (data && data.length > 0) {
          data.forEach((value) => {
            sum += parseInt(value.count);
          });
          data.forEach((value, index) => {
            list.push({
              no: index + 1,
              hashtag: value.word,
              engagement:
                helper.numeral(value.count) +
                ' (' +
                numeral((value.count / sum) * 100).format('0.00') +
                '%)',
            });
            totalCount += value.count;
          });
        }
      } else if (wordCloudMode.value === 'word') {
        data = wordMentionData.value;
        if (data && data.length > 0) {
          data.forEach((value) => {
            sum += parseInt(value.count);
          });
          data.forEach((value, index) => {
            list.push({
              no: index + 1,
              hashtag: value.word,
              engagement:
                helper.numeral(value.count) +
                ' (' +
                numeral((value.count / sum) * 100).format('0.00') +
                '%)',
            });
            totalCount += value.count;
          });
        }
      }
      return list;
    });

    const convertWordViewList = computed(() => {
      let data;
      const list = [];
      let sum = 0;
      totalCount = 0;
      if (wordCloudMode.value === 'hashtag') {
        data = hashtagViewData.value;
        if (data && data.length > 0) {
          data.forEach((value) => {
            sum += parseInt(value.count);
          });
          data.forEach((value, index) => {
            list.push({
              no: index + 1,
              hashtag: value.word,
              engagement:
                helper.numeral(value.count) +
                ' (' +
                numeral((value.count / sum) * 100).format('0.00') +
                '%)',
            });
            totalCount += value.count;
          });
        }
      } else if (wordCloudMode.value === 'word') {
        data = wordViewData.value;
        if (data && data.length > 0) {
          data.forEach((value) => {
            sum += parseInt(value.count);
          });
          data.forEach((value, index) => {
            list.push({
              no: index + 1,
              hashtag: value.word,
              engagement:
                helper.numeral(value.count) +
                ' (' +
                numeral((value.count / sum) * 100).format('0.00') +
                '%)',
            });
            totalCount += value.count;
          });
        }
      }
      return list;
    });

    const wordTable = computed(() => {
      if (mode.value) {
        if (mode.value.toLowerCase() === 'engagement') {
          return convertWordEngagementList.value;
        } else if (mode.value.toLowerCase() === 'mention') {
          return convertWordMentionList.value;
        } else if (mode.value.toLowerCase() === 'view') {
          return convertWordViewList.value;
        } else {
          return convertWordEngagementList.value;
        }
      } else {
        return convertWordEngagementList.value;
      }
    });

    const convertWordCloudEngagement = computed(() => {
      let data;
      const list = [];
      const maxValue = [];
      totalCount = 0;
      if (wordCloudMode.value === 'hashtag') {
        data = hashtagEngagementData.value;
      } else if (wordCloudMode.value === 'word') {
        data = wordEngagementData.value;
      }
      for (var i in data) {
        list.push([data[i]['word'], data[i]['count']]);
        maxValue.push(data[i]['count']);
        totalCount += data[i]['count'];
      }

      maxVal = Math.max.apply(null, maxValue);
      return list;
    });

    const convertWordCloudMention = computed(() => {
      let data;
      const list = [];
      const maxValue = [];
      totalCount = 0;
      if (wordCloudMode.value === 'hashtag') {
        data = hashtagMentionData.value;
      } else if (wordCloudMode.value === 'word') {
        data = wordMentionData.value;
      }
      for (var i in data) {
        list.push([data[i]['word'], data[i]['count']]);
        maxValue.push(data[i]['count']);
        totalCount += data[i]['count'];
      }

      maxVal = Math.max.apply(null, maxValue);
      return list;
    });

    const convertWordCloudView = computed(() => {
      let data;
      const list = [];
      const maxValue = [];
      totalCount = 0;
      if (wordCloudMode.value === 'hashtag') {
        data = hashtagViewData.value;
      } else if (wordCloudMode.value === 'word') {
        data = wordViewData.value;
      }
      for (var i in data) {
        list.push([data[i]['word'], data[i]['count']]);
        maxValue.push(data[i]['count']);
        totalCount += data[i]['count'];
      }

      maxVal = Math.max.apply(null, maxValue);
      return list;
    });

    const wordCloud = computed(() => {
      if (mode.value) {
        if (mode.value.toLowerCase() === 'engagement') {
          return convertWordCloudEngagement.value;
        } else if (mode.value.toLowerCase() === 'mention') {
          return convertWordCloudMention.value;
        } else if (mode.value.toLowerCase() === 'view') {
          return convertWordCloudView.value;
        } else {
          return convertWordCloudEngagement.value;
        }
      } else {
        return convertWordCloudEngagement.value;
      }
    });

    const selectModeType = computed(() => {
      if (mode.value) {
        if (mode.value.toLowerCase() === 'engagement') {
          return convertWordEngagementList.value;
        } else if (mode.value.toLowerCase() === 'mention') {
          return convertWordMentionList.value;
        } else if (mode.value.toLowerCase() === 'view') {
          return convertWordViewList.value;
        } else {
          return convertWordEngagementList.value;
        }
      } else {
        return convertWordEngagementList.value;
      }
    });

    const drilldownFn = (pointOption) => {
      let f = {
        payload: {
          title: `Data for "${helper.capitalize(pointOption)}"`,
        },
        criteria: {
          category: filterResult.value.category,
          subCategory: filterResult.value.subCategory,
          time: filterResult.value.time,
          keywordPhrase: pointOption,
          sort: {
            direction: 'desc',
            field: 'engagement_score',
          },
          highlight: {
            enable: true,
          },
        },
      };
      store.dispatch('message/showMessageModal', f);
    };

    const initWordCloud = async () => {
      const result = wordCloud.value;
      const minSize = 15;
      const maxSize = 28;
      const options = {
        gridSize: 1,
        drawOutOfBound: false,
        rotateRatio: 0,
        // rotationSteps: 0,
        shuffle: false,
        shrinkToFit: true,
        fontFamily: 'DM Sans, IBM Plex Sans Thai',
        weightFactor: function (size) {
          let p = helper.numeral((size / maxVal) * maxSize);
          if (!isNaN(p)) {
            if (p >= maxSize) p = maxSize;
            if (p <= minSize) p = minSize;
          } else {
            p = 11;
          }
          return p;
        },
        color: function (word, weight) {
          let weightColors = helper.numeral((weight / totalCount) * 100);
          return weightColors > 10
            ? '#009EFA'
            : weightColors > 5
            ? '#44b6f8'
            : '#8BDAFE';
        },
        click: function (item) {
          drilldownFn('("' + item[0] + '")');
        },
      };

      await WordCloud(wc.value, {
        list: result,
        ...options,
      });
    };

    const init = async () => {
      loading.value = true;
      if (mode.value.toLowerCase() === 'engagement') {
        if (wordCloudMode.value === 'hashtag') {
          columns.value = columnByMode[mode.value];
          await initHashtagEngagementData();
        } else if (wordCloudMode.value === 'word') {
          columnsWords.value = columnsWordsByMode[mode.value];
          await initWordEngagementData();
        }
      } else if (mode.value.toLowerCase() === 'mention') {
        if (wordCloudMode.value === 'hashtag') {
          columns.value = columnByMode[mode.value];
          await initHashtagMentionData();
        } else if (wordCloudMode.value === 'word') {
          columnsWords.value = columnsWordsByMode[mode.value];
          await initWordMentionData();
        }
      } else if (mode.value.toLowerCase() === 'view') {
        if (wordCloudMode.value === 'hashtag') {
          columns.value = columnByMode[mode.value];
          await initHashtagViewData();
        } else if (wordCloudMode.value === 'word') {
          columnsWords.value = columnsWordsByMode[mode.value];
          await initWordViewData();
        }
      }
      hideOverflowTitle();
      loading.value = false;
    };

    const hideOverflowTitle = () => {
      const allSegment = document.querySelectorAll('.no-text');
      if (allSegment) {
        allSegment.forEach((obj) => {
          if (obj.offsetWidth > obj.parentElement.offsetWidth) {
            obj.style.visibility = 'hidden';
          } else {
            obj.style.visibility = 'visible';
          }
        });
      }
    };

    const setData = async () => {
      await init(filterResult.value);
      initWordCloud();
    };

    watch(
      () => filterResult.value,
      async () => {
        await setData();
      }
    );

    watch(
      () => wordCloudMode.value,
      async () => {
        await setData();
      }
    );

    watch(
      () => mode.value,
      async () => {
        await setData();
      }
    );

    onBeforeMount(async () => {
      await setData();
    });
    return {
      wordCloudMode,
      modeOptions,
      loading,
      isMobileScreen,
      columns,
      columnsWords,
      wordTable,
      wordCloud,
      wc,
      selectModeType,
      numeral,
      helper,
      hideOverflowTitle,
      rowClick,
    };
  },
};
</script>

<style lang="scss" scoped>
.ant-btn.ant-btn-primary {
  background: rgba(51, 113, 255, 0.1);
  color: rgba(51, 113, 255);
}
.table-trending {
  cursor: pointer;
  padding-right: 15px;
}
.align-right {
  text-align: right;
  padding-right: 40px;
}
.title {
  padding-left: 15px;
  color: #868eae;
  font-size: 12px;
  min-width: 120px;
  flex: 0 0 120px;
  text-transform: capitalize;
}
.word-cloud-page {
  .ant-table-thead > tr:first-child > th:first-child {
    border-radius: 0px !important;
    border-left: 0px !important;
  }
  .ant-table-thead > tr:last-child > th:last-child {
    border-radius: 0px !important;
    border-right: 0px !important;
  }

  .ant-dropdown-menu {
    min-width: 0;
  }

  .loading-wrapper {
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0px 20px;
  }

  .word-cloud-content {
    padding: 30px 0;

    .word-cloud-card {
      align-self: center;
      text-align: center;
      .wordCloud-canvas {
        width: 100%;
        max-width: fit-content;
      }

      @media (max-width: 768px) {
        margin-bottom: 20px;
      }
    }
    .word-list-block {
      min-height: 610px;
      height: 610px;
      overflow-y: auto;

      .table-word-cloud {
        .no-text {
          font-weight: 500;
          color: #272b41;
        }

        .ant-table-without-column-header table {
          color: #5a5f7d;
        }
      }
    }
  }

  .ant-card-head .ant-card-head-wrapper {
    flex-flow: row;
    align-items: center;
  }
  .ant-card-head-wrapper .ant-card-extra {
    padding: 0px !important;
  }
  .more-menu {
    color: #9299b8;
  }
}
#wc {
  cursor: pointer;
}

@media (max-width: 768px) {
  .word-cloud-page .word-cloud-content .word-list-block {
    min-height: 700px;
    height: 700px;
  }
  .ant-btn.ant-btn-ghost {
    background: rgba(244, 245, 247, 0.6);
    border-radius: 36px;
    color: #9299b8;
  }
  .btn-line {
    margin-right: 5px;
  }

  .ant-btn.ant-btn-primary > span > .icon-font-case {
    filter: invert(36%) sepia(39%) saturate(3877%) hue-rotate(212deg)
      brightness(99%) contrast(105%);
  }
}
</style>
