<template>
  <a-card class="post-card">
    <a-tabs v-model:activeKey="activeKey">
      <a-tab-pane
        key="1"
        tab="Top 10 Most Engaged Post"
        :forceRender="true"
      ></a-tab-pane>
      <a-tab-pane
        key="2"
        tab="Top 10 Influencer"
        :forceRender="true"
      ></a-tab-pane>
    </a-tabs>
    <div
      v-if="loading"
      class="load-wrapper"
    >
      <a-skeleton
        :loading="loading"
        active
      />
    </div>
    <div
      v-if="!loading && checkNoDataLists"
      class="load-wrapper"
    >
      <NoDataFound></NoDataFound>
    </div>
    <div v-if="!loading && !checkNoDataLists">
      <ChannelTopEngage
        v-if="activeKey === '1'"
        :filterResult="filter"
        :topPost="topPostList"
      ></ChannelTopEngage>
      <div
        v-if="activeKey === '2'"
        class="item-table"
      >
        <div
          v-if="isMobileScreen"
          class="flex-align-right mt-2 w-100"
          style="column-gap: 8px"
        >
          <div
            id="sort-selector"
            class="flex-align-right mobile"
            style="flex: 1 1 50%"
          >
            <div class="text-title mr-2">Sort by:</div>
            <a-select
              ref="select-sort"
              v-model:value="sortBy"
              :getPopupContainer="(trigger) => trigger.parentNode"
              style="width: 70%"
              @change="init"
            >
              <a-select-option value="engagement">Engagement</a-select-option>
              <a-select-option value="count">Mention count</a-select-option>
              <a-select-option value="like_reaction_count"
                >Like/Reaction count</a-select-option
              >
              <a-select-option value="comment_count"
                >Comment count</a-select-option
              >
              <a-select-option value="share_count">Share count</a-select-option>
              <a-select-option value="view_count">View count</a-select-option>
            </a-select>
          </div>
        </div>
        <InfluencerLists
          v-if="isMobileScreen"
          :influencerDataList="influencerList"
          :sortBy="sortBy"
          :filterResult="filterResult"
          :campaign="campaign"
        ></InfluencerLists>
        <InfluencerTable
          v-else
          :influencerList="influencerList"
          :sortBy="sortBy"
          :filterResult="filterResult"
          :campaign="campaign"
        ></InfluencerTable>
      </div>
    </div>
  </a-card>
</template>

<script>
import { ref, toRefs, reactive, watch, computed, onBeforeMount } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { notification } from 'ant-design-vue';
import api from '@/services/api';
import helper from '@/services/helper';
import ChannelTopEngage from './ChannelTopEngage.vue';
import InfluencerTable from '@/components/Table/InfluencerTable.vue';
import InfluencerLists from '@/components/Lists/InfluencerLists.vue';
import NoDataFound from '@/components/Error/NoDataFound.vue';
export default {
  name: 'CampaignChannelPost',
  components: {
    ChannelTopEngage,
    InfluencerTable,
    InfluencerLists,
    NoDataFound,
  },
  props: {
    filterResult: Object,
    source: String,
  },
  setup(props) {
    const store = useStore();
    const route = computed(() => useRoute());
    const campaignLevel = computed(
      () => store.state.account.campaignMenuData.categoryLevel
    );
    const campaignName = computed(() =>
      decodeURIComponent(route.value.params.id)
        ? decodeURIComponent(route.value.params.id).toLowerCase()
        : ''
    );
    const campaign = ref({
      name: campaignName.value,
      level: campaignLevel.value,
    });
    const { filterResult: filter } = toRefs(props);
    const activeKey = ref('1');
    const sortBy = ref('engagement');
    const influencerList = ref([]);
    let loading = ref(false);
    const error = ref(false);
    const limitCount = ref(10);
    const isMobileScreen = computed(() => store.state.isMobileScreen);
    const topPostList = reactive({
      data: [],
      subCategoryName: {},
    });

    const initEngagement = async (fv) => {
      loading.value = true;
      error.value = false;
      if (fv && fv.source.length !== 0) {
        const result = await api
          .getSearchData({
            ...fv,
            sort: {
              field: 'engagement_score',
              direction: 'desc',
            },
            highlight: {
              enable: true,
            },
            source: fv.source,
          })
          .catch(() => {
            error.value = true;
          });
        if (result && result.message && result.message.data) {
          const highlightResult = await store.dispatch(
            'config/highlightKeywords',
            {
              messageList: result.message.data,
              truncateAt: 0,
            }
          );
          topPostList.data = highlightResult;
          topPostList.subCategoryName = result.message.subCategoryName;
        }
      }
      loading.value = false;
    };

    const initInfluencer = async (fv) => {
      loading.value = true;
      error.value = false;
      if (fv && fv.source) {
        const finalCriteria = {
          ...fv,
          nodeLimit: limitCount.value,
        };
        const result = await api
          .getInfluencerByMode(sortBy.value, finalCriteria)
          .catch(() => {
            notification.error({
              message: 'Error',
              description: 'Cannot get influencer data.',
            });
          });
        if (result.message && result.message.influencers) {
          const { influencers, peopleInfo } = result.message;
          let finalResult = [];
          for (let peopleObj of influencers) {
            const info = peopleInfo[peopleObj.id];
            let totalMention = 0;
            let mention = {};
            for (let a of peopleObj.actions) {
              mention[a.key] = helper.numeral(a.value, '0,0', false, null);
              totalMention += a.value;
            }
            // let totalEngage = 0;
            let totalEngage = peopleObj.total_engagement;
            let engagement = {};
            for (let a of peopleObj.reactions) {
              engagement[a.key] = helper.numeral(a.value, '0,0', false, null);
              // totalEngage += a.value;
            }
            const newProfileImage = info.user_photo;
            const fallbackImage = info.fallback_user_photo;

            finalResult.push({
              ...peopleObj,
              info,
              totalMention: helper.numeral(totalMention),
              totalEngage: helper.numeral(totalEngage),
              sentiment: {
                positive: helper.numeral(peopleObj.sentiment_count[2].value),
                neutral: helper.numeral(peopleObj.sentiment_count[1].value),
                negative: helper.numeral(peopleObj.sentiment_count[0].value),
              },
              mention,
              engagement,
              newProfileImage,
              fallbackImage,
            });
          }
          influencerList.value = finalResult;
          // totalCount.value = finalResult.length;
        }
      }
      loading.value = false;
    };

    const initInfluencerSort = () => {
      if (activeKey.value === '2') {
        initInfluencer(filter.value);
      }
    };

    const checkNoDataLists = computed(() => {
      let result = false;
      if (activeKey.value === '1') {
        result = topPostList.data.length <= 0;
      } else if (activeKey.value === '2') {
        result = influencerList.value.length <= 0;
      }
      return result;
    });

    watch(
      () => filter.value,
      () => {
        setData();
      }
    );

    // watch(
    //   () => source.value,
    //   () => {
    //     if (activeKey.value === '1') {
    //       initEngagement(filter.value);
    //     } else if (activeKey.value === '2') {
    //       initInfluencer(filter.value);
    //     }
    //   },
    // );

    const setData = () => {
      if (activeKey.value === '1') {
        initEngagement(filter.value);
      } else if (activeKey.value === '2') {
        initInfluencer(filter.value);
      }
    };

    watch(
      () => activeKey.value,
      () => {
        setData();
      }
    );

    watch(
      () => sortBy.value,
      () => {
        if (isMobileScreen.value && activeKey.value === '2') {
          initInfluencer(filter.value);
        }
      }
    );

    onBeforeMount(() => {
      setData();
    });

    return {
      filter,
      activeKey,
      topPostList,
      influencerList,
      sortBy,
      initInfluencerSort,
      loading,
      error,
      checkNoDataLists,
      isMobileScreen,
      campaign,
    };
  },
};
</script>
<style lang="scss" scoped>
.post-card {
  .selecter-sort {
    padding-top: 20px;
    justify-content: right;
  }
  .item-table {
    width: 100%;
    overflow: auto;
  }
}
</style>
